import './src/styles/global.css';
// exports.onRouteUpdate = ({ location }) => scrollToAnchor(location)
//
// function scrollToAnchor(location, mainNavHeight = 0) {
//     // Check for location so build does not fail
//     if (location && locatin.hash) {
//         const hash = location.hash.replace(/^#(\d)/, '#\\3$1');
//         const item = document.querySelector(`${hash}`).offsetTop
//
//         window.scrollTo({
//             top: item - mainNavHeight,
//             behavior: "smooth",
//         })
//     }
//
//     return true
// }
